<template>
  <section class="contact-section bg-black">
    <div class="container p-4 px-lg-5">
      <div class="social d-flex justify-content-center">
        <a class="mx-2" href="https://www.linkedin.com/in/anwar-korti/" target="_blank"><img class="rounded img-fluid float-right" src="../assets/img/linkedin.png" alt="..."></a>
      </div>
    </div>
  </section>
</template>
<script>


export default {
  name: 'footer',

  data: () => {
    return {
      typeValue: '',
      typeStatus: false,
      typeArray: ['conception', 'developpement', 'conseils'],
      typingSpeed: 150,
      erasingSpeed: 50,
      newTextDelay: 500,
      typeArrayIndex: 0,
      charIndex: 0
    }
  },
  components: {

  }
}
</script>
