<template>

  <section class="projects-section" id="expertise">
    <div class="container px-4 px-lg-5">
      <div class="row">

        <div class="col-xl-6 col-lg-6">
          <div class="light-header light-header-grey text-md-end">
            <p>Mon</p>
          </div>
          <div class="title_big_header text-md-end">
            <p class="animate-on-scroll initiale-position " data-animation="slide-left-to-right">Expertise.</p>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6">
          <div class="text-left text-lg-left">
            <div class="list-group-flush mb-0 animate-on-scroll initiale-position" data-animation="fade-in">
              <div v-for="item in expertises.elements" :key="item.id" class="list-group-item">
                <p class="mb-0"><font-awesome-icon icon="circle" size="xs" class="pink-text" />
                  {{ item.content }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <section class="text-center" id="techno">
    <div class="py-5">
      <div class="container">
        <div class="row align-items-center">
          <Carousel :itemsToShow="4">
            <Slide v-for="slide in techno.slides" :key="slide.id">
              <div class="carousel__item"><img :src="'https://api.ak-web.ch'+slide.image.url" class="img-fluid " :key="slide.id"></div>
            </Slide>
            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </section>

  <!-- Section: Testimonials v.2 -->
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


library.add(faCircle)

export default {
  name: 'displayTechno',
  props: ['techno', 'expertises'],
  data:() => {
    return {
      slides: [
          {src: "img/technos/symfony_black_03.png" },
          {src: "img/technos/sylius-black.png" },
          {src: "img/technos/shopify-black.png" },
          {src: "img/technos/vuejs-black.png" },
          {src: "img/technos/wordpress-black.png"},
          {src: "img/technos/aws-black.png" },
          {src: "img/technos/googlecloud-black.png"},
          ]
    }
  },
  methods: {
    getImgUrl: function (imagePath) {
      return require('@/assets/' + imagePath);
    }
  },
  components: {
    Carousel,
    Navigation,
    Slide,
    FontAwesomeIcon
  },
  created() {

  }
}
</script>
<style scoped>
</style>
