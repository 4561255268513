<template>
  <displayHeader :menu="menu"/>
  <displayAbout :about="about" />
  <displayTechno :techno="techno" :expertises="expertise" />
  <displayDonation :help="help"/>
  <displayClient :testimonials="testimonial"/>
  <displayForm/>
  <displayContact/>
  <displayFooter/>
</template>
<script>
import displayHeader from './components/Header.vue'
import displayAbout from './components/About.vue'
import displayTechno from './components/Techno.vue'
import displayFooter from './components/Footer.vue'
import displayContact from './components/Contact.vue'
import displayClient from './components/Client.vue'
import displayDonation from './components/Donation.vue'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import headful from 'headful'

const token = 'cbea9dc8bd1b0297d3dc78203d52ede2c9b91bb64810a6665f211f52bb995b04ef8ba4007ba4d210e21e694b274c8cb3039beda1ed8f459745d61073015804df3f64e5a5b8fe6c69801aea0ad5252e3160e59cf89eefa927909e30d07050881e7b53edff93e6c40cbf217b1633da87a62e76db505ad49ffc03b451b7de9116e3';

export default {
  name: 'app',
  components: {
    displayHeader,
    displayAbout,
    displayTechno,
    displayFooter,
    displayContact,
    displayClient,
    displayDonation
  },
  data:() => {
    return {
      info: null,
      slides: null,
      about: {},
      expertise: {},
      techno: {},
      help: {},
      testimonial: {},
      menu:  {},
    }
  },
  mounted: function() {
      var elementsToShow = document.querySelectorAll('.animate-on-scroll');
      document.addEventListener('scroll', function () {

        Array.prototype.forEach.call(elementsToShow, function(element){
          var classCss = element.dataset.animation;
          if (isInViewport(element)) {
            setTimeout(startAnimation(element, classCss), 10000);
          }
        });

      }, {
        passive: true
      });
      this.getData()

      function startAnimation(element, classCss){
        element.classList.add(classCss);
        element.classList.remove('initiale-position');
      }

      function isInViewport(element) {
        var rect = element.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
        return isVisible;
      }
  }, methods: {
    getData() {
      axios
          .get('https://api.ak-web.ch/api/homepage', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(response => {
            this.about = response.data.about
            this.expertise = response.data.expertise
            this.help = response.data.help
            this.techno = response.data.techno
            this.testimonial = response.data.testimonial
            this.menu[response.data.about.code]=response.data.about.name
            this.menu[response.data.expertise.code]=response.data.expertise.name
            this.menu[response.data.help.code]=response.data.help.name
          }).catch(error => {
            // Handle error.
            console.log('An error occurred:', error.response);
          });
    }
  }
}
headful({
  title: 'AK Web - Agence Developpement Web',
  // * <title>
  // * <meta itemprop="name">
  // * <meta property="og:title">
  // * <meta name="twitter:title">

  description: 'Agence Spécialisé dans la conception et dans le développement de solutions digitales',
  // * <meta name="description">
  // * <meta itemprop="description">
  // * <meta property="og:description">
  // * <meta name="twitter:description">
  image: 'https://www.ak-web.ch/img/computer.24d17e83.jpg',
  // * <meta itemprop="image">
  // * <meta property="og:image">
  // * <meta name="twitter:image">

  lang: 'fr',
  // * <html lang>
  // * <meta property="og:locale"> - only for language codes like ll-CC

  ogLocale: 'fr',
  // * <meta property="og:locale">

  url: 'https://ak-web.ch',
  // <link rel="canonical">
  // <meta property="og:url">
  // <meta name="twitter:url">

});
</script>

<style>
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
/* Import one of the Vue Flow Form CSS themes (optional) */
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,800;1,300&display=swap');
@import './assets/css/styles.css';

</style>
