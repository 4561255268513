<template>
  <section class="signup-section" id="contact">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto">
          <h2 class="text-white" v-if="!success">Parlons de votre projet!</h2>
          <h2 class="text-white" v-if="success">
            {{ this.success_message }}
          </h2>
          <form @submit.prevent="submit" class="form-signup" id="contactForm"  v-if="!success">
            <p class="text-left mb-4">Laissez mois un message et profitons d'un instant pour échanger sur votre projet et avoir une vision plus claire de sa construction.</p>

            <!-- Email address input-->
            <div class="row input-group-newsletter">
              <div class="col">
                <input v-model="firstname"  class="form-control" id="firstname" type="text" placeholder="Votre prénom..." aria-label="Votre prénom..." >
              </div>
              <div class="col">
                <input v-model="lastname"  class="form-control" id="lastname" type="text" placeholder="Votre nom..." aria-label="Votre nom..." >
              </div>
            </div>

            <div class="row mt-4 input-group-newsletter">
              <div class="col">
                <input v-model="email"  class="form-control" id="email" type="email" placeholder="Votre email..." aria-label="Votre email..." >
              </div>
              <div class="col">
                <input v-model="phone"  class="form-control" id="phone" type="text" placeholder="Votre numéro de téléphone..." aria-label="Votre numéro de téléphone..." >
              </div>

            </div>


            <div class="row mt-4 form-group-newsletter">
              <div class="col">
                <textarea v-model="message"  class="form-control" id="message" placeholder="Votre message..." aria-label="Votre message..." ></textarea>
              </div>

            </div>
            <div class="row mt-4 form-group-newsletter">
              <button class="btn btn-dark " id="submitButton" type="submit">Envoyer</button></div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const token = 'cbea9dc8bd1b0297d3dc78203d52ede2c9b91bb64810a6665f211f52bb995b04ef8ba4007ba4d210e21e694b274c8cb3039beda1ed8f459745d61073015804df3f64e5a5b8fe6c69801aea0ad5252e3160e59cf89eefa927909e30d07050881e7b53edff93e6c40cbf217b1633da87a62e76db505ad49ffc03b451b7de9116e3';
import axios from 'axios';

export default {
  name: "contact",
  data() {
    return {
      email : '',
      message : "",
      firstname : "",
      lastname :"",
      phone : "",
      mail: {
        to: "anwarkorti@gmail.com",
        subject: "Nouveau contact",
        text: null
      },
      success: false,
      success_message: 'Je vous remercie pour votre message et je reviendrais vers vous très rapidement'
    };
  },
  computed: {
    formValid() {
      const { firstname,email, message } = this;
      return (
          firstname.length > 0 &&
          /(.+)@(.+){2,}.(.+){2,}/.test(email) &&
          message.length > 0
      );
    },
  },
  methods: {
    onReset() {
      this.email = "";
      this.message = "";
      this.firstname = "";
      this.lastname = "";
      this.phone = "";
    },
    submit() {
      if (!this.formValid) {
        return;
      }
      if (!localStorage.getItem("messages")) {
        localStorage.setItem("messages", JSON.stringify([]));
      }
      const messages = JSON.parse(localStorage.getItem("messages"));
      const { firstname,lastname,phone, email, message } = this;
      this.mail.text= message
      messages.push({
        firstname,
        lastname,
        email,
        phone,
        message
      });
      localStorage.setItem("messages", JSON.stringify(messages));
      this.success = axios.post('https://api.ak-web.ch/api/email',this.mail,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(function (response) {
            return response.data
          })
          .catch();

    },
  },
};
</script>
