<template>
  <section class="client-section" id="testimonial">
    <div class="container px-4 px-lg-5" >
      <div class="col-xl-12 col-lg-12">
        <div class="light-header-client title_big_header light-header-grey text-md-end">
          <p>Les <span class="client-title">Avis.</span></p>
        </div>
      </div>
        <Carousel :itemsToShow="1" :autoplay="0" :wrapAround="1" >
          <Slide v-for="testimonial in testimonials.elements" :key="testimonial.id">
            <div class="carousel__item">
              <div class="row d-flex">
                <div class="col-lg-12 text-md-end">
                  <p class="text-muted h5 font-italic">
                    <font-awesome-icon icon="quote-left" size="2x" class="pink-text" />
                    {{ testimonial.content }}
                  </p>
                  <h5 class="font-strong text-right">{{ testimonial.author }}</h5>
                  <p class="mt--0-6">{{ testimonial.position }}</p>

                </div>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>
      </div>
  </section>
  <!-- Section: Testimonials v.2 -->
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


library.add(faCircle)
library.add(faQuoteLeft)

export default {
  name: 'displayClient',
  props: ['testimonials'],
  methods: {
    getImgUrl: function (imagePath) {
      return require('@/assets/' + imagePath);
    }
  },
  components: {
    Carousel,
    Pagination,
    Slide,
    FontAwesomeIcon
  },
  created() {

  }
}
</script>
