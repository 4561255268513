<template>
  <section class="donation-section donation-bg" id="help" >

      <div class="row">
        <div class="col-xl-6 col-lg-6 donation-padding">
          <div class="container">
            <div class="light-header light-header-grey text-md-end">
              <p>Mon</p>
            </div>
            <div class="title_big_header text-md-end">
              <p >Engagement.</p>
            </div>
        </div>
      </div>
        <div class="col-xl-6 col-lg-6 donation-head">
          <div class="container px-4 px-lg-5">
            <div class="">
              <div class="donation">
                <h3 class="text-black">{{ help.title }}</h3>
                <p  class="mb-0 animate-on-scroll initiale-position" data-animation="fade-in">{{ help.content }}</p>
              </div>
            </div>
          </div>
        </div>
    </div>
  </section>
  <!-- Section: Testimonials v.2 -->
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';


library.add(faCircle)
library.add(faQuoteLeft)

export default {
  name: 'displayDonation',
  props: ['help'],
  data:() => {
    return {
      testimonials: [
        {name: 'Vincent Plomb', profession: 'Créateur de Vinceterra', text: 'Design is not making beauty, beauty emerges from selection, affinities, integration, love.' },
        {name: 'John Doe', profession: 'bla bla', text: 'Lorem Ipsum' },
        {name: 'Vincent Plomb', profession: 'Créateur de Vinceterra', text: 'Lorem Ipsum' }
      ]
    }
  },
  methods: {
    getImgUrl: function (imagePath) {
      return require('@/assets/' + imagePath);
    }
  },
  components: {
  },
  created() {

  }
}
</script>
