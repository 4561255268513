<template>
  <section class="bg-black pt-4 pb-4" id="about">
    <div class="container px-4 px-lg-5">
      <!-- Featured Project Row-->
      <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
        <div class="col-xl-6 col-lg-6">
          <img class="rounded img-fluid float-right" src="../assets/img/computer.jpg" alt="...">
        </div>
        <div class="col-xl-6 col-lg-6">
            <div class="light-header">
              <p>Artisan</p>
            </div>
            <div class="title_big_header">
              <p class="animate-on-scroll initiale-position" data-animation="slide-right-to-left">Digital.</p>
            </div>
            <div class="text-left text-lg-left pt-4 ">
              <h3 class="text-white">{{ about.title }}</h3>
              <p class="mb-0 animate-on-scroll initiale-position" data-animation="fade-in">{{ about.content }}</p>
            </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'displayAbout',
  props: ['about']
}

</script>
